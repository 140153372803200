<template>
  <el-select v-model="selectedTerm" clearable
			 :style="marginLeft?'margin-left: 10px;':''"
			 placeholder="请选择学年学期"
			 @change="selectTermChange">
	<el-option v-for="term in termList" :key="term.id"
			   :label="term.name" :value="term.id"/>
  </el-select>
</template>

<script>
export default {
  name: "termSelector",
  props: {
	//margin-left: 10px;
	marginLeft: Boolean,
	yearid1:[Number,String]
  },
  data() {
	return {
	  selectedTerm: '',
	  termList: [],
	}
  },
  watch:{
	  yearid1(newValue){
		  this.selectedTerm = newValue
	  }
  },
  created() {
	this.termList = JSON.parse(sessionStorage.getItem("userInfo")).semesters
	this.selectedTerm = this.yearid1
  },
  methods: {
	selectTermChange(value) {
	  console.log(value)
	  this.$emit('change', value)

	},
  }
}
</script>

<style scoped>

</style>
